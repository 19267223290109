import prod from "./config.prod.json";
import prodvanderlande from "./config.prod.vanderlande.json";
import acc from "./config.acc.json";
import accvanderlande from "./config.acc.vanderlande.json";
import dev from "./config.dev.json";
import devvanderlande from "./config.dev.vanderlande.json";
import local from "./config.local.json";

let config: {
    environment?: string;
    clientId?: string;
    authority?: string;
    redirectUri?: string;
    apiScopes?: string[];
    graphScopes?: string[];
    apiUrl?: string;
} = {};

switch (window.location.hostname) {
    case "edgeman.vanderlande.com":
    case "azapp-iotedgetoolui-prod-001.azurewebsites.net":
        config = prod;
        break;
    case "azapp-iotedgetoolui-prod-002.azurewebsites.net":
        config = prodvanderlande;
        break;
    case "edgeman.acc.vanderlande.com":
    case "azapp-iotedgetoolui-acc-001.azurewebsites.net":
        config = acc;
        break;
    case "azapp-iotedgetoolui-acc-002.azurewebsites.net":
        config = accvanderlande;
        break;
    case "azapp-iotedgetoolui-dev-001.azurewebsites.net":
        config = dev;
        break;
    case "azapp-iotedgetoolui-dev-002.azurewebsites.net":
        config = devvanderlande;
        break;
    case "localhost":
        config = local;
        break;
    default:
        console.log(`Could not figure out environment, hostname: ${window.location.hostname}`);
        config = local;
}

export default config;
